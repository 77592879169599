import React, { Component } from 'react'
import { Button, FormGroup, InputGroup, Switch } from '@blueprintjs/core'
import { DateInput } from '@blueprintjs/datetime'
import TextareaAutosize from 'react-autosize-textarea'
import { API } from 'aws-amplify'
import { Flex, Box } from 'reflexbox'
import dayjs from 'dayjs'
import withAuth from '../../components/withAuth'
import Title from '../../components/Title'
import getRef from '../../utils/getRef'
import './styles.css'

class PatientForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            isEditForm:
                props.match.params &&
                props.match.params.ref &&
                props.match.params.ref.length > 0,
            patientData: {
                name: '',
                patientId: '',
                dob: null,
                sex: '',
                insurance: '',
                insuranceId: '',
                insurance2: '',
                insurance2Id: '',
                deceased: false,
                deceasedAt: null,
                phone: '',
                email: '',
                address: '',
                notes: ''
            }
        }
    }

    async componentWillMount() {
        if (this.state.isEditForm) {
            this.setState({ isLoading: true })
            try {
                const patient = await API.get(
                    'ehr',
                    `/patients/${this.props.match.params.ref}`
                )

                this.setState({
                    isLoading: false,
                    patientData: patient.data
                })
            } catch (error) {
                // TODO: mostrar toaster con error
                console.log('Error', error)
                this.setState({
                    isLoading: false
                })
            }
        }
    }

    validateForm() {
        return (
            this.state.patientData.name.length > 0 &&
            // this.state.patientData.patientId.length > 0 &&
            this.state.patientData.sex.length > 0
        )
    }

    handleChange = event => {
        let value = event.target.value

        if (event.target.id === 'deceased') {
            value = event.target.checked
        }
        const tmpStateData = this.state.patientData
        tmpStateData[event.target.id] = value

        this.setState({
            patientData: tmpStateData
        })
    }

    handleChangeDOBDate = dateValue => {
        const tmpStateData = this.state.patientData
        tmpStateData.dob = dateValue

        this.setState({
            patientData: tmpStateData
        })
    }

    handleChangeDeceasedDate = dateValue => {
        const tmpStateData = this.state.patientData
        tmpStateData.deceasedAt = dateValue

        this.setState({
            patientData: tmpStateData
        })
    }

    handleSubmit = async event => {
        event.preventDefault()

        this.setState({ isLoading: true })

        let createOrUpdate = this.createPatient
        if (this.props.match.params.ref) {
            createOrUpdate = this.updatePatient
        }

        try {
            const data = {
                name: this.state.patientData.name,
                patientId: this.state.patientData.patientId,
                sex: this.state.patientData.sex,
                insurance: this.state.patientData.insurance,
                insuranceId: this.state.patientData.insuranceId,
                insurance2: this.state.patientData.insurance2,
                insurance2Id: this.state.patientData.insurance2Id,
                deceased: this.state.patientData.deceased,
                deceasedAt: this.state.patientData.deceasedAt || null,
                phone: this.state.patientData.phone,
                email: this.state.patientData.email,
                address: this.state.patientData.address,
                notes: this.state.patientData.notes
            }

            if (this.state.patientData.dob) {
                data.dob = dayjs(this.state.patientData.dob).toISOString()
            }

            const patient = await createOrUpdate(
                data,
                this.props.match.params.ref
            )

            const ref = getRef(patient.data.ref)

            this.props.history.push(`/patients/${ref}`)
        } catch (e) {
            alert(e)
            this.setState({ isLoading: false })
        }
    }

    createPatient(patient) {
        return API.post('ehr', '/patients', {
            body: patient
        })
    }

    updatePatient(patient, ref) {
        return API.put('ehr', `/patients/${ref}`, {
            body: patient
        })
    }

    render() {
        const { patientData } = this.state
        patientData.dob = patientData.dob && dayjs(patientData.dob).toDate()
        patientData.deceasedAt =
            patientData.deceasedAt && dayjs(patientData.deceasedAt).toDate()

        return (
            <div className="PatientForm mc-container">
                <Title
                    text={
                        this.state.isEditForm
                            ? 'Editar Paciente'
                            : 'Nuevo Paciente'
                    }
                    isLoading={this.state.isLoading}
                />
                <form className="bp3-form" onSubmit={this.handleSubmit}>
                    <Flex align="center" justify="space-between">
                        <Box w={1 / 2} mr={'5px'}>
                            <FormGroup label="Nombre">
                                <InputGroup
                                    id="name"
                                    type="text"
                                    defaultValue={patientData.name}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                        <Box w={1 / 2} ml={'5px'}>
                            <FormGroup label="DNI/LC/CI/etc">
                                <InputGroup
                                    id="patientId"
                                    type="text"
                                    defaultValue={patientData.patientId}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                    </Flex>
                    <Flex align="center" justify="space-between">
                        <Box w={1 / 2} mr={'5px'}>
                            <FormGroup label="Fecha de Nacimiento">
                                <DateInput
                                    id="dob"
                                    onChange={this.handleChangeDOBDate}
                                    placeholder="DD/MM/AAAA"
                                    minDate={dayjs()
                                        .subtract(120, 'year')
                                        .toDate()}
                                    maxDate={dayjs().toDate()}
                                    // defaultValue={patientData.dob}
                                    value={patientData.dob}
                                    formatDate={date =>
                                        dayjs(date).format('DD/MM/YYYY')
                                    }
                                    parseDate={str => dayjs(str).toDate()}
                                />
                            </FormGroup>
                        </Box>
                        <Box w={1 / 2} ml={'5px'}>
                            <FormGroup label="Sexo">
                                <div className="bp3-select">
                                    <select
                                        id="sex"
                                        value={patientData.sex}
                                        onChange={this.handleChange}
                                    >
                                        <option value="">--</option>
                                        <option value="M">Masculino</option>
                                        <option value="F">Femenino</option>
                                        <option value="Other">Otro</option>
                                    </select>
                                </div>
                            </FormGroup>
                        </Box>
                    </Flex>

                    <Flex align="center" justify="space-between">
                        <Box w={1 / 2} mr={'5px'}>
                            <Switch
                                id="deceased"
                                label="Fallecido"
                                checked={patientData.deceased}
                                onChange={this.handleChange}
                            />
                        </Box>
                        <Box w={1 / 2} ml={'5px'}>
                            {patientData.deceased && (
                                <FormGroup label="Fecha de Muerte">
                                    <DateInput
                                        id="deceasedAt"
                                        onChange={this.handleChangeDeceasedDate}
                                        value={patientData.deceasedAt}
                                        formatDate={date =>
                                            date.toLocaleDateString('es-AR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                            })
                                        }
                                        parseDate={str => new Date(str)}
                                    />
                                </FormGroup>
                            )}
                        </Box>
                    </Flex>

                    <Flex align="center" justify="space-between">
                        <Box w={1 / 2} mr={'5px'}>
                            <FormGroup label="Obra Social">
                                <InputGroup
                                    id="insurance"
                                    type="text"
                                    defaultValue={patientData.insurance}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                        <Box w={1 / 2} ml={'5px'}>
                            <FormGroup label="Obra Social #">
                                <InputGroup
                                    id="insuranceId"
                                    type="text"
                                    defaultValue={patientData.insuranceId}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                    </Flex>

                    <Flex align="center" justify="space-between">
                        <Box w={1 / 2} mr={'5px'}>
                            <FormGroup label="Obra Social 2">
                                <InputGroup
                                    id="insurance2"
                                    type="text"
                                    defaultValue={patientData.insurance2}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                        <Box w={1 / 2} ml={'5px'}>
                            <FormGroup label="Obra Social 2 #">
                                <InputGroup
                                    id="insurance2Id"
                                    type="text"
                                    defaultValue={patientData.insurance2Id}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                    </Flex>

                    <Flex align="center" justify="space-between">
                        <Box w={1 / 2} mr={'5px'}>
                            <FormGroup label="Teléfono">
                                <InputGroup
                                    id="phone"
                                    type="text"
                                    defaultValue={patientData.phone}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                        <Box w={1 / 2} ml={'5px'}>
                            <FormGroup label="Email">
                                <InputGroup
                                    id="email"
                                    type="email"
                                    defaultValue={patientData.email}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                    </Flex>

                    <Flex align="center" justify="space-between">
                        <Box w={1}>
                            <FormGroup label="Dirección">
                                <TextareaAutosize
                                    className="bp3-input bp3-fill"
                                    id="address"
                                    value={patientData.address}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                    </Flex>
                    <Flex align="center" justify="space-between">
                        <Box w={1}>
                            <FormGroup label="Notes">
                                <TextareaAutosize
                                    className="bp3-input bp3-fill"
                                    id="notes"
                                    rows={10}
                                    value={patientData.notes}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                    </Flex>
                    <Button
                        disabled={!this.validateForm()}
                        loading={this.state.isLoading}
                        type="submit"
                        intent="primary"
                    >
                        {this.state.isEditForm ? 'Guardar' : 'Agregar paciente'}
                    </Button>
                </form>
            </div>
        )
    }
}

export default withAuth(PatientForm)
