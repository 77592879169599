import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { API } from 'aws-amplify'
import { Flex, Box } from 'reflexbox'
import { HTMLTable } from '@blueprintjs/core'
import dayjs from 'dayjs'
import withAuth from '../../components/withAuth'
import Title from '../../components/Title'
import DetailItem from '../../components/DetailItem'
import getRef from '../../utils/getRef'
import './styles.css'

class PatientDetail extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            patientData: {},
            visits: []
        }
    }

    async componentWillMount() {
        this.setState({ isLoading: true })
        try {
            const patient = await API.get(
                'ehr',
                `/patients/${this.props.match.params.ref}`
            )

            const visits = await API.get(
                'ehr',
                `/visits/list/${this.props.match.params.ref}`
            )
            console.log('visits', visits)

            this.setState({
                isLoading: false,
                patientData: patient.data,
                visits: (visits && visits.visits && visits.visits.data) || []
            })
        } catch (error) {
            // TODO: mostrar toaster con error
            console.log('Error', error)
            this.setState({
                isLoading: false
            })
        }
    }

    render() {
        console.log('render', this.state)
        const { patientData, visits } = this.state

        return (
            <div className="PatientDetail mc-container mc-container-full">
                <Title
                    text={`Paciente: ${patientData.name || ''}`}
                    isLoading={this.state.isLoading}
                />

                <Flex align="flex-start" justify="space-between">
                    <Box w={2 / 3} mr="5px">
                        <Link
                            to={`/patients/edit/${this.props.match.params.ref}`}
                            className="bp3-button bp3-icon-book"
                        >
                            Editar Paciente
                        </Link>

                        <Flex align="center" justify="space-between">
                            <Box w={1 / 2} mr={'5px'}>
                                <DetailItem
                                    title="Nombre"
                                    content={patientData.name}
                                    isLoading={this.state.isLoading}
                                />
                            </Box>
                            <Box w={1 / 2} ml={'5px'}>
                                <DetailItem
                                    title="DNI/LC/CI/etc"
                                    content={patientData.patientId}
                                    isLoading={this.state.isLoading}
                                />
                            </Box>
                        </Flex>
                        <Flex align="center" justify="space-between">
                            <Box w={1 / 2} mr={'5px'}>
                                <DetailItem
                                    title="Fecha de Nacimiento"
                                    content={
                                        patientData.dob &&
                                        dayjs(patientData.dob).format(
                                            'DD/MM/YYYY'
                                        )
                                    }
                                    isLoading={this.state.isLoading}
                                />
                            </Box>
                            <Box w={1 / 2} ml={'5px'}>
                                <DetailItem
                                    title="Sexo"
                                    content={
                                        {
                                            M: 'Masculino',
                                            F: 'Femenino',
                                            Other: 'Otro'
                                        }[patientData.sex]
                                    }
                                    isLoading={this.state.isLoading}
                                />
                            </Box>
                        </Flex>

                        <Flex align="center" justify="space-between">
                            <Box w={1 / 2} mr={'5px'}>
                                <DetailItem
                                    title="Fallecido"
                                    content={patientData.deceased ? 'Si' : 'No'}
                                    isLoading={this.state.isLoading}
                                />
                            </Box>
                            <Box w={1 / 2} ml={'5px'}>
                                {patientData.deceased &&
                                    patientData.deceasedAt && (
                                        <DetailItem
                                            title="Fecha de Muerte"
                                            content={
                                                patientData.deceasedAt &&
                                                dayjs(
                                                    patientData.deceasedAt
                                                ).format('DD/MM/YYYY')
                                            }
                                            isLoading={this.state.isLoading}
                                        />
                                    )}
                            </Box>
                        </Flex>
                        {patientData.insurance && (
                            <Flex align="center" justify="space-between">
                                <Box w={1 / 2} mr={'5px'}>
                                    <DetailItem
                                        title="Obra Social"
                                        content={patientData.insurance}
                                        isLoading={this.state.isLoading}
                                    />
                                </Box>
                                <Box w={1 / 2} ml={'5px'}>
                                    <DetailItem
                                        title="Obra Social #"
                                        content={patientData.insuranceId}
                                        isLoading={this.state.isLoading}
                                    />
                                </Box>
                            </Flex>
                        )}
                        {patientData.insurance2 && (
                            <Flex align="center" justify="space-between">
                                <Box w={1 / 2} mr={'5px'}>
                                    <DetailItem
                                        title="Obra Social 2"
                                        content={patientData.insurance2}
                                        isLoading={this.state.isLoading}
                                    />
                                </Box>
                                <Box w={1 / 2} ml={'5px'}>
                                    <DetailItem
                                        title="Obra Social 2 #"
                                        content={patientData.insurance2Id}
                                        isLoading={this.state.isLoading}
                                    />
                                </Box>
                            </Flex>
                        )}
                        <Flex align="center" justify="space-between">
                            <Box w={1 / 2} mr={'5px'}>
                                <DetailItem
                                    title="Teléfono"
                                    content={patientData.phone}
                                    isLoading={this.state.isLoading}
                                />
                            </Box>
                            <Box w={1 / 2} ml={'5px'}>
                                <DetailItem
                                    title="Email"
                                    content={patientData.email}
                                    isLoading={this.state.isLoading}
                                />
                            </Box>
                        </Flex>

                        <Flex align="center" justify="space-between">
                            <Box w={1}>
                                <DetailItem
                                    title="Dirección"
                                    content={patientData.address}
                                    isLoading={this.state.isLoading}
                                />
                            </Box>
                        </Flex>
                        <Flex align="center" justify="space-between">
                            <Box w={1}>
                                <DetailItem
                                    title="Notas"
                                    content={patientData.notes}
                                    isLoading={this.state.isLoading}
                                />
                            </Box>
                        </Flex>
                    </Box>
                    <Box w={1 / 3} mr="5px" className="mc-visitas-box">
                        <h2>Visitas</h2>

                        <HTMLTable
                            bordered={false}
                            condensed={true}
                            interactive={true}
                            striped={true}
                            className="mc-visitas-box-table"
                        >
                            <tbody>
                                {visits.length === 0 && (
                                    <tr>
                                        <td>No posee visitas.</td>
                                    </tr>
                                )}

                                {visits.length > 0 &&
                                    visits.map(visit => {
                                        const date = dayjs(
                                            visit.data.date['@ts']
                                        ).format('DD-MM-YYYY')
                                        const editLink = `/patients/${getRef(
                                            visit.data.patient
                                        )}/visit/edit/${getRef(visit.ref)}`

                                        return (
                                            <tr key={visit.ts}>
                                                <td>{date}</td>
                                                <td>
                                                    <Link
                                                        to={editLink}
                                                        className="bp3-button bp3-icon-edit"
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </HTMLTable>

                        <Link
                            to={`/patients/${
                                this.props.match.params.ref
                            }/visit/new`}
                            className="bp3-button bp3-icon-calendar"
                        >
                            Agregar Visita
                        </Link>
                    </Box>
                </Flex>
            </div>
        )
    }
}

export default withAuth(PatientDetail)
