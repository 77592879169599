import React from 'react'
import { Spinner } from '@blueprintjs/core'
import { Flex, Box } from 'reflexbox'

const Title = params => {
    return (
        <Flex>
            <Box>
                <h1>{params.text}</h1>
            </Box>
            {params.isLoading && (
                <Box pt="25px" ml="5px">
                    <Spinner size={25} />
                </Box>
            )}
        </Flex>
    )
}

export default Title
