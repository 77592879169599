import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './containers/Home'
import Login from './containers/Login'
import PatientForm from './containers/PatientForm'
import VisitForm from './containers/VisitForm'
import PatientDetail from './containers/PatientDetail'
import PatientList from './containers/PatientList'
import NotFound from './containers/NotFound'

import AppliedRoute from './components/AppliedRoute'

export default ({ childProps }) => (
    <Switch>
        <AppliedRoute path="/" exact component={Home} props={childProps} />
        <AppliedRoute
            path="/login"
            exact
            component={Login}
            props={childProps}
        />
        <AppliedRoute
            path="/patients/new"
            exact
            component={PatientForm}
            props={childProps}
        />
        <AppliedRoute
            path="/patients/edit/:ref"
            exact
            component={PatientForm}
            props={childProps}
        />
        <AppliedRoute
            path="/patients/:ref"
            exact
            component={PatientDetail}
            props={childProps}
        />
        <AppliedRoute
            path="/patients"
            exact
            component={PatientList}
            props={childProps}
        />
        <AppliedRoute
            path="/patients/:refPatient/visit/new"
            exact
            component={VisitForm}
            props={childProps}
        />
        <AppliedRoute
            path="/patients/:refPatient/visit/edit/:refVisit"
            exact
            component={VisitForm}
            props={childProps}
        />
        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
    </Switch>
)
