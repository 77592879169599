import React from 'react'

const DetailItem = params => {
    const cn = params.isLoading ? 'bp3-skeleton' : ''
    return (
        <div className="mc-detail-item">
            <h4>{params.title}</h4>
            <div
                className={cn}
                style={{
                    whiteSpace: 'pre-wrap'
                }}
            >
                {params.content || '.'}
            </div>
        </div>
    )
}

export default DetailItem
