export default {
    apiGateway: {
        REGION: 'us-east-1',
        URL: process.env.REACT_APP_API_URL
        // URL: 'https://0ounsuk12k.execute-api.us-east-1.amazonaws.com/production'
        // URL: 'https://kcfzwgptnc.execute-api.us-east-1.amazonaws.com/dev'
    },
    cognito: {
        REGION: 'us-east-1',
        USER_POOL_ID: 'us-east-1_0g4bsHPMI',
        APP_CLIENT_ID: '79h281m9qn60vnkk884ho86jgm',
        IDENTITY_POOL_ID: 'us-east-1:04bc7e7f-4200-4927-adb4-c1b2447c548e'
    }
}
