import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Alignment, Button, Navbar } from '@blueprintjs/core'
import Amplify, { Auth, I18n } from 'aws-amplify'
import config from './config'
import './App.css'
import Routes from './Routes'

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
        endpoints: [
            {
                name: 'ehr',
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            }
        ]
    }
})

const authScreenLabels = {
    es: {
        'Sign in to your account': 'Accede a tu cuenta',
        'Forget your password? ': 'Olvidaste tu contraseña? ',
        'Reset password': 'Crear nueva contraseña',
        'Sign In': 'Acceder',
        Username: 'Email',
        'Enter your username': 'Ingrese su email',
        'Enter your password': 'Ingrese su contraseña'
    }
}
I18n.setLanguage('es')
I18n.putVocabularies(authScreenLabels)

class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true
        }
    }

    async componentDidMount() {
        try {
            await Auth.currentSession()
            this.userHasAuthenticated(true)
        } catch (e) {
            if (e !== 'No current user') {
                alert(JSON.stringify(e))
            }
        }

        this.setState({ isAuthenticating: false })
    }

    userHasAuthenticated = authenticated => {
        console.log('settingAsAuthenticated', authenticated)
        this.setState({ isAuthenticated: authenticated })
    }

    handleLogout = async () => {
        await Auth.signOut()
        this.userHasAuthenticated(false)
        this.props.history.push('/login')
    }

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated
        }

        return (
            !this.state.isAuthenticating && (
                <div className="App container">
                    <Navbar className="bp3-dark">
                        <Navbar.Group align={Alignment.LEFT}>
                            <Navbar.Heading>MC EHR</Navbar.Heading>
                            {this.state.isAuthenticated && (
                                <Fragment>
                                    <Navbar.Divider />
                                    <Link
                                        to="/"
                                        className="bp3-button bp3-minimal bp3-icon-home"
                                    >
                                        Inicio
                                    </Link>

                                    <Link
                                        to="/patients"
                                        className="bp3-button bp3-minimal bp3-icon-people"
                                    >
                                        Pacientes
                                    </Link>
                                    <Link
                                        to="/patients/new"
                                        className="bp3-button bp3-minimal bp3-icon-person"
                                    >
                                        Agregar Paciente
                                    </Link>
                                </Fragment>
                            )}
                        </Navbar.Group>
                        <Navbar.Group align={Alignment.RIGHT}>
                            {this.state.isAuthenticated ? (
                                <Button onClick={this.handleLogout}>
                                    Logout
                                </Button>
                            ) : (
                                <Link
                                    to="/login"
                                    className="bp3-button bp3-icon-log-in"
                                >
                                    Acceder
                                </Link>
                            )}
                        </Navbar.Group>
                    </Navbar>
                    <Routes childProps={childProps} />
                </div>
            )
        )
    }
}

export default withRouter(App)
