import React, { Component } from 'react'
import { ButtonGroup, Divider, HTMLTable } from '@blueprintjs/core'
import { Link } from 'react-router-dom'
import { API } from 'aws-amplify'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import relativeTime from 'dayjs/plugin/relativeTime'
import withAuth from '../../components/withAuth'
import Alert from '../../components/Alert'
import Title from '../../components/Title'
import './styles.css'

dayjs.locale('es')
dayjs.extend(relativeTime)

class PatientList extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isAlertOpen: false,
            patientToDelete: null,
            patients: []
        }
    }

    async componentDidMount() {
        try {
            const data = await API.get('ehr', '/patients/list')
            if (data && data.patients && data.patients.data) {
                this.setState({
                    isLoading: false,
                    patients: data.patients.data
                })
            }
        } catch (e) {
            // TODO: add toast with error
            alert(e)
            this.setState({ isLoading: false })
        }
    }

    openDeleteAlert(patient) {
        this.setState({
            isAlertOpen: true,
            patientToDelete: patient
        })
    }

    closeDeleteAlert() {
        this.setState({
            isAlertOpen: false,
            patientToDelete: null
        })
    }

    deletePatient() {
        console.log('delete patient', this.state.patientToDelete)
        // TODO:
        // - set loading to button
        // - delete from fauna
        // - refresh data
        // - remove from patientToDelete
        // - close alert
    }

    render() {
        return (
            <div className="PatientList mc-container">
                <Title text="Pacientes" isLoading={this.state.isLoading} />

                <div className="bp3-input-group">
                    <span className="bp3-icon bp3-icon-search" />
                    <input
                        className="bp3-input"
                        type="search"
                        placeholder="Buscar pacientes"
                        dir="auto"
                    />
                </div>

                <HTMLTable
                    bordered={true}
                    condensed={true}
                    interactive={true}
                    striped={true}
                    className="mc-table"
                >
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Edad</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.patients.map(patient => {
                            const patientRef = patient.ref['@ref'].id
                            const age = dayjs(patient.data.dob).toNow(true)
                            return (
                                <tr key={patientRef}>
                                    <td>
                                        <Link to={`/patients/${patientRef}`}>
                                            {patient.data.name}
                                        </Link>
                                    </td>
                                    <td>{age}</td>
                                    <td className="mc-table-actions">
                                        <ButtonGroup minimal>
                                            <Link
                                                to={`/patients/edit/${patientRef}`}
                                                className="bp3-button bp3-minimal bp3-icon-edit"
                                            >
                                                Editar
                                            </Link>
                                            <button
                                                onClick={this.openDeleteAlert.bind(
                                                    this,
                                                    patient.data
                                                )}
                                                className="bp3-button bp3-minimal bp3-icon-trash mc-delete-button"
                                            >
                                                Eliminar
                                            </button>
                                            <Divider />
                                            <Link
                                                to={`/patients/${patientRef}`}
                                                className="bp3-button bp3-minimal bp3-icon-book"
                                            >
                                                Ver Paciente
                                            </Link>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </HTMLTable>
                <Alert
                    className="bp3-dark"
                    isOpen={this.state.isAlertOpen}
                    icon="trash"
                    content={
                        <p>
                            Estás seguro que deseas borrar el paciente:{' '}
                            {this.state.patientToDelete &&
                                this.state.patientToDelete.name}
                        </p>
                    }
                    cancelButtonText="Cancelar"
                    confirmButtonText="Borrar"
                    intent="danger"
                    onCancel={this.closeDeleteAlert.bind(this)}
                    onConfirm={this.deletePatient.bind(this)}
                />
            </div>
        )
    }
}

export default withAuth(PatientList)
