import React from 'react'
import {
    withAuthenticator,
    Greetings,
    SignIn,
    ConfirmSignIn,
    RequireNewPassword,
    VerifyContact,
    ForgotPassword,
    TOTPSetup
} from 'aws-amplify-react'
import LoginTheme from './LoginTheme'

export default function withAuth(Component) {
    return withAuthenticator(Component, {
        includeGreetings: false,
        authenticatorComponents: [
            <Greetings />,
            <SignIn />,
            <ConfirmSignIn />,
            <RequireNewPassword />,
            <VerifyContact />,
            <ForgotPassword />,
            <TOTPSetup />
        ],
        federated: false,
        theme: LoginTheme
    })
}
