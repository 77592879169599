import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@blueprintjs/core'

class AlertComponent extends Component {
    render() {
        return (
            <Alert
                className={this.props.className}
                cancelButtonText={this.props.cancelButtonText}
                confirmButtonText={this.props.confirmButtonText}
                icon={this.props.icon}
                intent={this.props.intent}
                isOpen={this.props.isOpen}
                onCancel={this.props.onCancel}
                onConfirm={this.props.onConfirm}
            >
                {this.props.content}
            </Alert>
        )
    }
}

AlertComponent.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    icon: PropTypes.string,
    content: PropTypes.element.isRequired,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    intent: PropTypes.string,
    className: PropTypes.string
}

AlertComponent.defaultProps = {
    isOpen: false,
    icon: 'info-sign',
    cancelButtonText: 'Cerrar',
    intent: 'primary'
}

export default AlertComponent
