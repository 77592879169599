import React, { Component } from 'react'
import { Authenticator, Greetings, SignUp } from 'aws-amplify-react'
import { Auth, Hub } from 'aws-amplify'
import LoginTheme from '../components/LoginTheme'

export default class Login extends Component {
    componentDidMount() {
        const self = this
        Hub.listen('auth', async capsule => {
            const { channel, payload } = capsule
            if (channel === 'auth' && payload.event === 'signIn') {
                await self.checkUser()
            }
        })
    }

    async checkUser() {
        const user = await Auth.currentAuthenticatedUser()
        if (user.username) {
            this.props.history.push('/?loggedIn')
        }
    }

    render() {
        return <Authenticator hide={[Greetings, SignUp]} theme={LoginTheme} />
    }
}
