import React, { Component } from 'react'
import { Button, FormGroup, InputGroup } from '@blueprintjs/core'
import { DateInput } from '@blueprintjs/datetime'
import TextareaAutosize from 'react-autosize-textarea'
import { API } from 'aws-amplify'
import { Flex, Box } from 'reflexbox'
import dayjs from 'dayjs'
import withAuth from '../../components/withAuth'
import Title from '../../components/Title'
import getRef from '../../utils/getRef'
import './styles.css'

class VisitForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: false,
            isEditForm:
                props.match.params &&
                props.match.params.refVisit &&
                props.match.params.refVisit.length > 0,
            visitData: {
                patientId: '',
                date: null,
                reason: '',
                diagnostic: '',
                evolution: '',
                lab: '',
                densitometry: '',
                notes: ''
            },
            patient: null
        }
    }

    async componentWillMount() {
        try {
            const patient = await API.get(
                'ehr',
                `/patients/${this.props.match.params.refPatient}`
            )
            this.setState({ isLoading: false, patient })
        } catch (error) {
            // TODO: mostrar toaster con error
            console.log('Error', error)
            this.setState({
                isLoading: false
            })
        }
        if (this.state.isEditForm) {
            this.setState({ isLoading: true })
            try {
                const visit = await API.get(
                    'ehr',
                    `/visits/${this.props.match.params.refVisit}`
                )

                this.setState({
                    isLoading: false,
                    visitData: visit.data
                })
            } catch (error) {
                // TODO: mostrar toaster con error
                console.log('Error', error)
                this.setState({
                    isLoading: false
                })
            }
        }
    }

    validateForm() {
        return (
            this.state.visitData.date && this.state.visitData.reason.length > 0
        )
    }

    handleChange = event => {
        let value = event.target.value

        const tmpStateData = this.state.visitData
        tmpStateData[event.target.id] = value

        this.setState({
            visitData: tmpStateData
        })
    }

    handleChangeDate = dateValue => {
        const tmpStateData = this.state.visitData
        tmpStateData.date = dateValue

        this.setState({
            visitData: tmpStateData
        })
    }

    handleSubmit = async event => {
        event.preventDefault()

        this.setState({ isLoading: true })

        let createOrUpdate = this.createVisit
        if (this.props.match.params.refVisit) {
            createOrUpdate = this.updateVisit
        }

        try {
            const patientRef = this.props.match.params.refPatient

            const visit = await createOrUpdate(
                {
                    date: dayjs(this.state.visitData.date).toISOString(),
                    patientRef: patientRef,
                    reason: this.state.visitData.reason,
                    diagnostic: this.state.visitData.diagnostic,
                    evolution: this.state.visitData.evolution,
                    lab: this.state.visitData.lab,
                    densitometry: this.state.visitData.densitometry,
                    notes: this.state.visitData.notes
                },
                this.props.match.params.refVisit
            )
            const ref = getRef(visit.data.ref)

            this.props.history.push(`/patients/${patientRef}/visit/${ref}`)
        } catch (e) {
            alert(e)
            this.setState({ isLoading: false })
        }
    }

    createVisit(visit) {
        return API.post('ehr', '/visits', {
            body: visit
        })
    }

    updateVisit(visit, ref) {
        return API.put('ehr', `/visits/${ref}`, {
            body: visit
        })
    }

    render() {
        const { visitData, patient, isEditForm } = this.state
        let visitDate = dayjs().toDate()

        if (visitData.date && typeof visitData.date['@ts'] !== 'undefined') {
            visitDate = dayjs(visitData.date['@ts']).toDate()
        } else if (visitData.date) {
            visitDate = dayjs(visitData.date).toDate()
        }

        const title =
            patient &&
            `${isEditForm ? 'Editar Visita' : 'Nueva Visita'} (${
                patient.data.name
            })`

        return (
            <div className="VisitForm mc-container">
                <Title text={title} isLoading={this.state.isLoading} />
                <form className="bp3-form" onSubmit={this.handleSubmit}>
                    <Flex align="center" justify="space-between">
                        <Box w={1 / 2} mr={'5px'}>
                            <FormGroup label="Fecha">
                                <DateInput
                                    id="date"
                                    onChange={this.handleChangeDate}
                                    placeholder="DD/MM/AAAA"
                                    minDate={dayjs()
                                        .subtract(50, 'year')
                                        .toDate()}
                                    maxDate={dayjs()
                                        .add(5, 'year')
                                        .toDate()}
                                    value={visitDate}
                                    formatDate={date =>
                                        dayjs(date).format('DD/MM/YYYY')
                                    }
                                    parseDate={str => dayjs(str).toDate()}
                                />
                            </FormGroup>
                        </Box>
                        <Box w={1 / 2} ml={'5px'}>
                            &nbsp;
                        </Box>
                    </Flex>
                    <Flex align="center" justify="space-between">
                        <Box w={1}>
                            <FormGroup label="Motivo de la consulta">
                                <InputGroup
                                    id="reason"
                                    type="text"
                                    defaultValue={visitData.reason}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                    </Flex>
                    <Flex align="center" justify="space-between">
                        <Box w={1}>
                            <FormGroup label="Diagnóstico">
                                <TextareaAutosize
                                    className="bp3-input bp3-fill"
                                    id="diagnostic"
                                    value={visitData.diagnostic}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                    </Flex>
                    <Flex align="center" justify="space-between">
                        <Box w={1}>
                            <FormGroup label="Evolución">
                                <TextareaAutosize
                                    className="bp3-input bp3-fill"
                                    id="evolution"
                                    value={visitData.evolution}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                    </Flex>
                    <Flex align="center" justify="space-between">
                        <Box w={1}>
                            <FormGroup label="Laboratorio">
                                <TextareaAutosize
                                    className="bp3-input bp3-fill"
                                    id="lab"
                                    value={visitData.lab}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                    </Flex>
                    <Flex align="center" justify="space-between">
                        <Box w={1}>
                            <FormGroup label="Densitometría">
                                <TextareaAutosize
                                    className="bp3-input bp3-fill"
                                    id="densitometry"
                                    value={visitData.densitometry}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                    </Flex>
                    <Flex align="center" justify="space-between">
                        <Box w={1}>
                            <FormGroup label="Notas">
                                <TextareaAutosize
                                    className="bp3-input bp3-fill"
                                    id="notes"
                                    value={visitData.notes}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Box>
                    </Flex>

                    <Button
                        disabled={!this.validateForm()}
                        loading={this.state.isLoading}
                        type="submit"
                        intent="primary"
                    >
                        {this.state.isEditForm
                            ? 'Editar visita'
                            : 'Agregar visita'}
                    </Button>
                </form>
            </div>
        )
    }
}

export default withAuth(VisitForm)
