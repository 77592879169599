import React, { Component } from 'react'
import './styles.css'

export default class Home extends Component {
    render() {
        return (
            <div className="Home">
                <div className="lander">
                    <h1>MC EHR</h1>
                    <p>Historia Clínica Digital</p>
                </div>
            </div>
        )
    }
}
